const ourStrategiesList = [
  {
    title: "Motilal Oswal Value Migration Strategy",
    link: "/our-strategies/motilal-oswal-value-migration-strategy",
    image: "/assets/images/header/value-migration.svg",
    style: {
      gradientStart: "tw-from-[#ED6F9F]",
      gradientStop: "tw-to-[#EB8A6A]",
      iconBackground: "tw-bg-[#FFB19A]"
    }
  },
  {
    title: "Motilal Oswal India Growth Fund",
    link: "/our-strategies/motilal-oswal-india-growth-fund",
    image: "/assets/images/header/india-growth.svg",
    style: {
      gradientStart: "tw-from-[#6998F4]",
      gradientStop: "tw-to-[#43BBFF]",
      iconBackground: "tw-bg-[#4BBBFF]"
    }
  },
  {
    title: "Motilal Oswal Founders Portfolio",
    link: "/our-strategies/motilal-oswal-founders-portfolio",
    image: "/assets/images/header/founders.svg",
    style: {
      gradientStart: "tw-from-[#444E7A]",
      gradientStop: "tw-to-[#7E86A9]",
      iconBackground: "tw-bg-[#9199BF]"
    }
  },
  {
    title: "Motilal Oswal Next Trillion Dollar Opportunity",
    link: "/our-strategies/motilal-oswal-next-trillion-dollar-opportunity",
    image: "/assets/images/header/next-trillion-dollar.svg",
    style: {
      gradientStart: "tw-from-[#B18EE9]",
      gradientStop: "tw-to-[#F6AAE1]",
      iconBackground: "tw-bg-[#FFB5EE]"
    }
  },
  {
    title: "Motilal Oswal Mid to Mega Strategy",
    link: "/our-strategies/motilal-oswal-mid-to-mega-strategy",
    image: "/assets/images/header/mid-to-mega.svg",
    style: {
      gradientStart: "tw-from-[#12A99D]",
      gradientStop: "tw-to-[#32D36E]",
      iconBackground: "tw-bg-[#31D271]"
    }
  },
]

const investorEducationList = [
  {
    title: "Blogs",
    link: `${process.env.NEXT_PUBLIC_MOTILAL_MF_MODULE_URL}investor-education/category/blog/`,
    external: true,
  },
  {
    title: "Videos",
    link: `${process.env.NEXT_PUBLIC_MOTILAL_MF_MODULE_URL}investor-education/category/videos/`,
    external: true,
  },
  {
    title: "Podcast",
    link: `${process.env.NEXT_PUBLIC_MOTILAL_MF_MODULE_URL}investor-education/category/podcast/`,
    external: true,
  },
  {
    title: "Press Release",
    link: "/Media-Room/Press-Release"
  },
  {
    title: "Interviews",
    link: "/Media-Room/Press-Release"
  },
  {
    title: "News",
    link: "/Media-Room/Press-Release"
  }
]

const toolsAndCalculatorsList = [
  // {
  //   title: "Compare Portfolio",
  //   link: `${process.env.NEXT_PUBLIC_MOTILAL_MF_MODULE_URL}tools/compare-portfolio`,
  //   external: true
  // },
  {
    title: "Update KYC",
    link: `${process.env.NEXT_PUBLIC_MOTILAL_MF_MODULE_URL}modify-kyc`,
    external: true
  }
]

const importantLinks = [
  {
    title: "Downloads",
    link: "/download/document-disclosure",
    isImportantLink: true
  },
  {
    title: "Investor Charter",
    link: "/assets/pdf/Investor_Charter_MOAMC.pdf",
    external: true,
    isImportantLink: true
  },
  {
    title: "Account Statement",
    link: `${process.env.NEXT_PUBLIC_MOTILAL_MF_MODULE_URL}reporthub`,
    external: true,
    isImportantLink: true
  },
  {
    title: "Contact Us",
    link: "/contact-us",
    isImportantLink: true
  },
]

const importantLinksPartnerServices = [
  {
    title: "Login",
    link: `${process.env.NEXT_PUBLIC_PARTNER_MODULE_URL}investonline/dist`,
    external: true,
    isImportantLink: true
  },
  {
    title: "Account Statement",
    link: `${process.env.NEXT_PUBLIC_MOTILAL_MF_MODULE_URL}reporthub`,
    external: true,
    isImportantLink: true
  },
]

const distributorServices = [
  {
    title: "Onboard New Client",
    link: "https://digipms.motilaloswalamc.com/login/",
    image: "/assets/images/header/onboard-new-client.svg",
    external: true
  },
  {
    title: "Downloads",
    link: "/download/document-disclosure",
    image: "/assets/images/header/downloads.svg"
  },
  {
    title: "Why Choose Us",
    link: `${process.env.NEXT_PUBLIC_PARTNER_MODULE_URL}`,
    image: "/assets/images/header/why-choose-us.svg",
    external: true
  },
  {
    title: "FAQs",
    link: `${process.env.NEXT_PUBLIC_PARTNER_MODULE_URL}faq`,
    image: "/assets/images/header/faq.svg",
    external: true
  },
]

const mobileHeaderLinks = {
  "Our Strategies": ourStrategiesList,
  "Investor Services": [
    {
      title: "Investor Education",
      isHeading: true
    },
    ...investorEducationList,
    // {
    //   title: "Tools & Calculators",
    //   isHeading: true
    // },
    // ...toolsAndCalculatorsList,
    {
      title: "Important Links",
      isHeading: true
    },
    ...importantLinks
  ],
  "Partner Services": [ 
    ...distributorServices,
    {
      title: "Important Links",
      isHeading: true
    },
    ...importantLinksPartnerServices
  ]
}

export {
  ourStrategiesList,
  investorEducationList,
  toolsAndCalculatorsList,
  importantLinks,
  distributorServices,
  mobileHeaderLinks,
  importantLinksPartnerServices
}