import { Popper } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { makeStyles } from "@mui/styles";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import DistributorServicesPopup from './DistributorServicesPopup';
import InvestorServicesPopup from './InvestorServicesPopup';
import MobileMenu from "./MobileMenu";
import OurStrategiesPopup from './OurStrategiesPopup';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import LoginButton from './loginButton'
import WhatsappPromoHeader from "./WhatsappPromoHeader"
import { useMediaQuery } from '@mui/material';
import ProductList from './ProductList'

const dropDownOptions = [
  { value: "mf", label: "Mutual Fund", fullText: "Mutual Fund", link: process.env.NEXT_PUBLIC_MOTILAL_MF_MODULE_URL },
  { value: "pms", label: "PMS", fullText: "Portfolio Management Services (PMS)", link: "/" },
  { value: "aif", label: "AIF", fullText: "Alternative Investment Funds (AIF)", link: process.env.NEXT_PUBLIC_AIF_MODULE_URL },
];

const useStyles = makeStyles({
  popper: {
    width: "100% !important",
    maxWidth: "650px !important",
    zIndex: 1201,

    '& .MuiAutocomplete-listbox': {
      scrollbarWidth: 'thin',
    },
  },
});

const CustomPopper = (props) => {
  const classes = useStyles();
  return <Popper {...props} className={classes.popper} placement="bottom-end" />
};

const index = () => {
  const [selectedOption, setSelectedOption] = useState("pms");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0)
  const isMobileHeader = useMediaQuery("(max-width:989px)")

  const classes = useStyles()
  const router = useRouter();

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [router]);


  const toggleMobileMenu = (value) => {
    setIsMobileMenuOpen(value);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileMenuOpen(false)
    }

    const handleScroll = () => {
      setScrollPosition(window.scrollY)
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [])

  // For any Promotional Header that goes above header.
  useEffect(() => {
    const isPromoHeaderPresent = true
    const promoHeaderSize = 60 //px
    const promoHeaderSizeMobile = 60 //px

    const root = document.querySelector(':root')
    if (isPromoHeaderPresent) {
      if (isMobileHeader)
        root.style.setProperty('--top-for-menu-popup', scrollPosition > promoHeaderSizeMobile ? '100px' : `${100 + promoHeaderSizeMobile}px`);
      else
        root.style.setProperty('--top-for-menu-popup', scrollPosition > promoHeaderSize ? '100px' : `${100 + promoHeaderSize}px`);
    }
  }, [isMobileHeader, scrollPosition])

  return (
    <>
      <WhatsappPromoHeader />
      <header className="headerRevamp tw-sticky tw-top-0 tw-left-0 tw-w-full tw-z-[1000]">
        <div className="tw-hidden tab:tw-block">
          <Desktop
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            classes={classes}
          />
        </div>
        <div className="tab:tw-hidden tw-block">
          <Mobile
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            setIsMobileMenuOpen={setIsMobileMenuOpen}
          />
          <Drawer
            open={isMobileMenuOpen}
            onClose={() => toggleMobileMenu(false)}
            anchor="left">
            <MobileMenu
              setIsMobileMenuOpen={setIsMobileMenuOpen}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              classes={classes}
              CustomPopper={CustomPopper}
              options={dropDownOptions}
              customicon={KeyboardArrowDownRoundedIcon}
            />
          </Drawer>
        </div>
      </header>
    </>
  );
};

const Desktop = ({
  selectedOption,
  setSelectedOption,
}) => {
  // state to handle hover effect
  const [openMenuItem, setOpenMenuItem] = useState("")
  const router = useRouter();

  const handleBlur = (event) => {
    event.preventDefault(); // Prevent the default blur behavior
  };

  useEffect(() => {
    const handleRouteChange = () => {
      setOpenMenuItem("");
    }

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router])

  return (
    <div className="tw-text-white tw-h-[100px] tw-bg-white tw-border-b tw-border-borderGrey tw-flex">
      <section className="container tw-flex">
        <div className=" tw-mt-[14px]">
          <Link
            href="/"
            title="Motilal Oswal Asset Management Company"
            className="tw-relative tw-block tw-h-[72px] tw-w-[124px] tw-mr-[24px]">
            <Image
              src="/assets/images/header/MO_Asset_Management_Logo.webp"
              alt="Motilal Oswal Asset Management Company Logo"
              fill
            />
          </Link>
        </div>

        <div className="tw-flex tw-w-full">
          {/* Left Section */}
          <nav className="tw-my-5">
            <div className="tw-w-fit fund-select">
              <ProductList
                defaultValue={selectedOption}
                options={dropDownOptions}
                customicon={KeyboardArrowDownRoundedIcon}
              />
            </div>
            <ul className="tw-mt-[15px] tw-flex tw-items-center tw-gap-x-[40px] tw-mt-[16px]">
              <li className="menu-item" onMouseEnter={() => setOpenMenuItem("ourStrategies")} onMouseLeave={() => setOpenMenuItem("")}>
                <Link
                  href="/our-strategies"
                  title="Our Strategies"
                  className="tw-text-[13px] desktop:tw-text-[16px] tw-text-blueGrey hover:tw-no-underline tw-leading-6 tw-inline tw-font-medium tw-relative tw-pb-5 after:tw-absolute after:tw-left-0 after:tw-bottom-2 after:tw-w-full after:tw-h-0.5 after:desktop:tw-h-1 after:tw-content-[''] after:tw-bg-yellow after:tw-transition-opacity after:tw-duration-300 after:tw-opacity-0 hover:tw-text-yellow hover:after:tw-opacity-100 tw-ml-[8px] tw-pb-[22px]">
                  Our Strategies
                </Link>
                {openMenuItem === "ourStrategies" ? <OurStrategiesPopup /> : null}
              </li>

              <li className="menu-item" onMouseEnter={() => setOpenMenuItem("investorServices")} onMouseLeave={() => setOpenMenuItem("")}>
                <p
                  className="tw-text-[13px] tw-cursor-default desktop:tw-text-[16px] tw-text-blueGrey hover:tw-no-underline tw-leading-6 tw-inline tw-font-medium tw-relative tw-pb-5 after:tw-absolute after:tw-left-0 after:tw-bottom-2 after:tw-w-full after:tw-h-0.5 after:desktop:tw-h-1 after:tw-content-[''] after:tw-bg-yellow after:tw-transition-opacity after:tw-duration-300 after:tw-opacity-0 hover:tw-text-yellow hover:after:tw-opacity-100  tw-pb-[22px]">
                  Investor Services
                </p>
                {openMenuItem === "investorServices" ? <InvestorServicesPopup /> : null}
              </li>

              <li className="menu-item" onMouseEnter={() => setOpenMenuItem("distributorServices")} onMouseLeave={() => setOpenMenuItem("")}>
                <p
                  className="tw-text-[13px] tw-cursor-default desktop:tw-text-[16px] tw-text-blueGrey  hover:tw-no-underline tw-leading-6 tw-inline tw-font-medium tw-relative tw-pb-5 after:tw-absolute after:tw-left-0 after:tw-bottom-2 after:tw-w-full after:tw-h-0.5 after:desktop:tw-h-1 after:tw-content-[''] after:tw-bg-yellow after:tw-transition-opacity after:tw-duration-300 after:tw-opacity-0 hover:tw-text-yellow hover:after:tw-opacity-100 tw-pb-[22px]">
                  Partner Services
                </p>
                {openMenuItem === "distributorServices" ? <DistributorServicesPopup /> : null}
              </li>

              {openMenuItem !== "" ? <div id="header-menu-overlay" className="tw-fixed tw-z-[-1] tw-top-[var(--top-for-menu-popup)] tw-left-0 tw-bg-[rgba(0,0,0,0.5)] tw-h-screen tw-w-screen tw-transition-transform tw-backdrop-blur-[1px] tw-invisible" /> : null}
            </ul>
          </nav>

          {/* Right Section */}
          <div className="tw-ml-auto tw-mt-4 tw-mb-5">
            {/* Top Section */}
            <div className='tw-flex tw-items-center tw-text-[12px] tw-leading-6'>
              <div className='tw-flex tw-items-center tw-pr-5 tw-border-r tw-border-buttonBorderGrey'>
                <div className='after:tw-block after:tw-w-[24px] after:tw-h-[24px] after:tw-bg-[url(/assets/images/header/whatsapp-icon.svg)] after:tw-bg-cover' />
                <Link href={"tel:+91 73049 21822"} title="+91 73049 21822" className='hover:tw-underline tw-text-[13px] hover:tw-text-blueGrey desktop:tw-text-sm tw-text-blueGrey tw-font-medium'>
                  +91 73049 21822
                </Link>
              </div>

              <div className="tw-flex tw-items-center tw-gap-2 tw-px-5 ">
                <p className="tw-text-[13px] desktop:tw-text-sm tw-text-blueGrey tw-font-medium">Download App</p>
                <div className='tw-flex tw-gap-2 tw-items-center'>
                  <Link href="https://apps.apple.com/in/app/motilal-oswal-mf-pms/id6463774883" title="Apple Appstore" target="_blank">
                    <Image src="/assets/images/header/ic_apple.svg" alt="Apple Appstore" height={16} width={16} />
                  </Link>
                  <Link href="https://play.google.com/store/apps/details?id=com.moamc.investorapp&hl=en_IN&gl=US" title="Google Playstore" target="_blank">
                    <Image src="/assets/images/header/ic_android_playstore.svg" alt="Google Playstore" height={16} width={16} />
                  </Link>
                </div>
              </div>

              <LoginButton />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const Mobile = ({
  selectedOption,
  setSelectedOption,
  setIsMobileMenuOpen,
}) => {

  return (
    <div className="tw-text-white tw-bg-white tw-flex tw-items-center">
      <div className="container tw-h-[104px]">
        <div className="tw-flex tw-items-center tw-border-b tw-border-borderColor tw-py-2">
          <div className="tw-w-fit fund-select">
            <ProductList
              defaultValue={selectedOption}
              options={dropDownOptions}
              customicon={KeyboardArrowDownRoundedIcon}
            />
          </div>
        </div>

        <div className="tw-flex tw-items-center tw-mt-3">
          <div className="tw-flex tw-gap-4 tw-items-center">
            <button onClick={() => setIsMobileMenuOpen(true)} className='after:tw-block after:tw-w-6 after:tw-h-6 after:tw-bg-[url(/assets/images/header/ic_menu_mobile.svg)] after:tw-bg-cover' />
            <Link
              href="/"
              title="Motilal Oswal Asset Management Company"
              className="tw-relative tw-h-[45px] tw-w-[82px]">
              <Image
                src="/assets/images/header/MO_Asset_Management_Logo.webp"
                alt="Motilal Oswal Asset Management Company Logo"
                fill
              />
            </Link>
          </div>
          <div className="tw-ml-auto">
            <LoginButton variant="outlined" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
